<template>
    <div id="register">
        <div class="contain" :style="mask?'filter:blur(2px)':''">
            <div class="register_banan">
                <img src="../../assets/questionnaire/registerBanner.png" alt="">
            </div>
            <div class="register_section">
                <div class="register_icon">
                    <img src="../../assets/questionnaire/sectionRightIcon.png" alt="">
                </div>
                <div class="register_title">
                    <h3> 您已成功获得<b>15天</b>小新课堂会员权益啦！</h3>
                    <p>填写信息马上免费领取吧~</p> 
                </div>
                <div class="register_form">
                    <div class="register_input">
                        <input type="text" v-model="phone" maxlength="11" @input="changeVal" placeholder="请输入手机号">
                    </div>
                    <div class="register_input">
                        <input type="text" v-model="code" maxlength="11" @input="changeVal" placeholder="请输入验证码">
                        <span :class="!codeDisbale?'active':''"  @click="getCode">
                            {{codeVal}}
                        </span>
                    </div>
                    <div class="register_button"  :class="[sumbitDisbale?'buttonActive':'']" @click="sumbitDisbale && sumbit()">
                        立即领取
                    </div>
                    <div class="register_down"  @click="downLoad">
                        去下载小新课堂APP
                    </div>
                </div>
            </div>
            <!-- <div class="register_rule">
                <span @click="hrefTexth5">去了解《问卷活动规则》</span>
            </div> -->
            <div class="maskTitle" v-if="maskShow">
                {{maskVal}}
            </div>
        </div>
        
        <div class="mask" v-if="mask" >
            <div class="maskContent">
                <div class="content_img">
                    <img src="../../assets/questionnaire/icon_downBg.png" alt="">
                </div>
                <div class="content_title">
                    成功领取15天VIP权益
                </div>
                <div class="content_desc">
                    海量幼儿+小学精品课程，马上下载体验吧~
                </div>
                <div class="content_down" @click="downLoad">
                    去下载小新课堂APP
                </div>
                <div class="content_cannel" @click="changeMask">
                    我再想想
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import API from '@/utils/request.js';
export default {
    name:'register',
    data(){
        return{
            system:'',
            phone:'',
            code:'',
            codeVal:'获取验证码',
            newList:[],
            codeDisbale:true,
            sumbitDisbale:false,
            mask:false,
            maskShow:false,
            maskVal:'',
            codeToken:'',
        }
    },
    mounted(){
        if(!this.$store.state.submit){
            this.$router.push({path:"/questionnaire"})
        }
        this.system = this.getUserSystem()
    },
    methods:{
        changeMask(){
            this.mask = !this.mask;
        },
        maskTip(val,time=0){
            this.maskVal = val;
            this.maskShow = true;
            setTimeout(() => {
                this.maskShow = false;
            }, time);
        },
        // 手机验证码
        async getCode(){
            if(!this.codeDisbale) return;
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/,that = this,num = 60;
            
            if(reg.test(this.phone)){
               API.registered({app_no:'A0001',phone:this.phone}).then(res=>{
                   this.codeDisbale = false
                   let isRegister = res.data.is_register
                    API.userAuth({app_no:'A0001',phone:this.phone,new_user:!isRegister}).then(res=>{
                        if(res){
                            that.maskTip('验证码已发送',2000)
                            that.codeToken = res.token
                            let time = setInterval(()=>{
                                    if(num>=0){
                                        that.codeVal = num + 's后重新获取'
                                        num--;
                                    }
                                    else{
                                        clearInterval(time)
                                        that.codeDisbale = true
                                        that.codeVal='获取验证码'
                                    }
                                },1000)
                            
                        }
                        else{
                            that.codeDisbale = true
                            that.codeVal='获取验证码'
                            that.maskTip('发送太频繁,请稍后重试',2000)
                        }
                    })   
                }).catch(err=>{
                    that.codeDisbale = true
                    that.codeVal='获取验证码'
                })
               
            }
            else{
                that.codeDisbale = true
                that.maskTip('请输入正确的手机号',2000)
                
            }
        },
        // 输入框
        changeVal(){
            if(this.phone.length==11 && this.code!=''){
                this.sumbitDisbale = true
            }
            else{
                this.sumbitDisbale = false
            }
        },
        // 提交
        sumbit(){
            let that = this;
            that.downDisbale = false;
            setTimeout(()=>{
                that.downDisbale = true
            },2000)
            let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
            if(this.phone==''){
                this.maskTip('请先输入手机号',2000)
                return false
            }
            if(!reg.test(this.phone)){
                this.maskTip('请先输入正确的手机号',2000)
                return false
            }
            if(this.codeToken==''){
                this.maskTip('请先发送验证码',2000)
                return false
            }
            if(this.code==''){
                this.maskTip('请先输入验证码',2000)
                return false
            }
            this.inviting()
        },  
        inviting(){
            let data = {
                app_no:'A0001',phone:this.phone,
                captcha:this.code,token:this.codeToken
            },that = this;
            API.captcha(data).then(res=>{
                if(res.data){
                    localStorage.setItem('token',res.data.token)
                    data.question_no='20220001';
                    API.inviting(data).then(res=>{
                        if(res.code==0){
                            that.mask = true;
                            that.phone = '';
                            that.code = '';
                            that.codeToken = '';
                            that.codeDisbale = true
                            that.codeVal='获取验证码'
                            localStorage.setItem('token','')
                        }
                        else{
                            that.maskTip(res.message,2000)
                        }
                    })
                }
                else{
                    that.maskTip(res.message,2000)
                }
            })
            
        },
        // 跳转协议
        hrefTexth5(){
            this.$router.push('/rule')
        },
        // 修改弹窗
        changeOpen(){
            this.down = !this.down
        },
        // 获取当前系统
        getUserSystem() {
            let u = navigator.userAgent,
                app = navigator.appVersion
            let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 //g
            // let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            let isIOS = u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)||u.indexOf('Ios') > -1; //ios终端
            if (isAndroid) {
                return 'android'
            } else if (isIOS) {
                return 'ios'
            } else {
                return 'android'
            }
        },
        // 下载
        downLoad(){
            let that = this;
            that.downDisbale = false,
            setTimeout(()=>{
                that.downDisbale = true
            },2000)
            if(this.system=='android'){
                 window.location.href='https://a.app.qq.com/o/simple.jsp?pkgname=com.sprout.xxkt&fromcase=40003'
            }
            else{
                window.location.href='https://apps.apple.com/cn/app/id1585777897'
            }
        },
    }
}
</script>
<style lang="scss">
body,html{
    min-width: auto;
}
@function s($num) {
	@return $num / (3.75) +vw
}
*{-moz-user-select: -moz-none;
  -moz-user-select: text;
  -o-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}  
#register{
    width:100%;
    min-height: 100vh;
    background: #F9FAFE;
    .register_banan{
        width: 100%;
        height: s(254.43);
        img{
            width: 100%;
            height: 100%;
        }
    }
    .register_section{
        width: s(343);
        height: s(372);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: s(2) s(2) s(12) rgba(102, 179, 255, 0.08);
        border-radius: s(16);
        margin: s(-49.43) auto 0;
        position: relative;
        padding:s(25) 0 s(20) ;
        box-sizing: border-box;
        .register_icon{
            position: absolute;
            top: s(-48);
            right: s(-7);
            width: s(81);
            height: s(65);
            img{
                width: 100%;
                height: 100%;
            }
        }
        .register_title{
            width: s(315);
            height: s(68);
            background-color: rgba(255, 248, 242, 1);
            border-radius: s(12);
            margin: 0 auto;
            padding:s(15) 0 s(15) s(12);
            box-sizing: border-box;
            h3{
                color: #000;
                font-size: s(16);
                line-height: s(20);
                b{
                    color: #FF8D1A;
                }
            }
            p{
                color: #FFB300;
                font-size: s(14);
                line-height: s(28);
            }
        }
        .register_form{
            width: 100%;
            padding: s(22) s(35) 0 s(36);
            box-sizing: border-box;
            .register_input{
                width: 100%;
                height: s(35);
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: s(1) solid #EEEEEE;
                margin-bottom: s(25);
                input{
                    width: 100%;
                    height: 100%;
                    border: none;
                    outline: none;
                    appearance: none;
                    color: #000;
                    font-size: s(16);
                }
                input::placeholder{
                    color: #868D94;
                }
                span{
                    color: rgba(44, 135, 255, 1);
                    font-size: s(14);
                    line-height: s(21);
                    white-space: nowrap;
                }
                .active{
                    color: #868D94;
                }
            }
            .register_input:nth-of-type(2){
                input{
                    width: s(180);
                }
                
            }
            .register_button{
                width: s(260);
                height: s(44);
                background-color: rgba(229, 229, 229, 1);
                border-radius: s(25);
                margin: s(50) auto 0;
                font-size: s(16);
                color: #FFFFFF;
                line-height: s(44);
                text-align: center;
            }
            .buttonActive{
                background: linear-gradient(181.29deg, rgba(84, 152, 255, 1) 0%, rgba(84, 192, 255, 1) 100%);
                box-shadow: s(2) s(2) s(24) rgba(84, 161, 255, 0.32);
            }
            .register_down{
                font-size: s(12);
                color: #65A8FF;
                text-align: center;
                margin-top: s(21);
            }
        }
    }
    .register_rule{
        text-align: center;
        white-space: nowrap;
        text-align: center;
        padding: s(29) 0 s(30);
        span{
            width: auto;
            color: rgba(134, 141, 148, 1);
            font-size: 12px;
        }
    }
    .maskTitle{
        position: fixed;
        top: 50%;
        left: 50%;
        display: inline-block;
        max-width:90%;
        padding: s(8) s(12);
        font-size: s(16);
        transform: translate(-50%,-50%);
        background-color: rgba(0, 0, 0, 0.7);
        border-radius: s(8);
        color: #FFFFFF;
        z-index: 3;

    }
    .mask{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 4;
        background-color: rgba(0, 0, 0, 0.24);
        display: flex;
        align-items: center;
        justify-content: center;
        &Content{
            width: s(300);
            height: s(280);
            background-color: rgba(255, 255, 255, 1);
            box-shadow: s(2) s(2) s(12) rgba(102, 179, 255, 0.08);
            border-radius: s(16);
            .content_img{
                width: s(140);
                height: s(140);
                margin: s(-80) auto 0;
                img{
                    width: 100%;
                    height: 100%;
                    vertical-align: top;
                }
            }
            .content_title{
                width: 100%;
                color: rgba(0, 0, 0, 1);
                font-size: s(20);
                text-align: center;
                padding:s(0) 0 s(12);
            }
            .content_desc{
                width: s(200);
                color: rgba(78, 78, 78, 1);
                font-size: s(16);
                line-height: s(20);
                text-align: center;
                margin: 0 auto;
            }
            .content_down{
                width: s(240);
                height: s(40);
                background: linear-gradient(181.29deg, rgba(84, 152, 255, 1) 0%, rgba(84, 192, 255, 1) 100%);
                box-shadow: s(2) s(2) s(24) rgba(84, 161, 255, 0.32);
                border-radius: s(25);
                line-height: s(40);
                text-align: center;
                color: rgba(255, 255, 255, 1);
                letter-spacing: s(0.48);
                font-size: s(16);
                margin: s(25) auto s(12);
            }
            .content_cannel{
                width: s(240);
                height: s(40);
                background-color: rgba(255, 255, 255, 1);
                border: s(1) solid rgba(194, 197, 206, 1);
                border-radius: s(22);
                text-align: center;
                line-height: s(40);
                color: rgba(134, 141, 148, 1);
                letter-spacing: s(0.42);
                font-size: s(14);
                margin: 0 auto;
            }
        }
    }
}
</style>